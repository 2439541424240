import React from 'react';

import {Link} from 'gatsby'

import ReactWOW from 'react-wow'
import bannerImg2 from '../../assets/images/banner-img22.png'


const MainBanner = () => {
    return (
        <div className="banner-section2">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-12">
                        <div className="banner-wrapper-content">
                            <span className="sub-title">DESARROLLO WEB</span>
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                            <h1>Un mundo a un clic de distancia</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>Excelentes procesos para excelentes aplicaciones.</p>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                    <Link to="/contact" className="default-btn">
                                        <i className="flaticon-right"></i> 
                                        Contáctanos
                                        <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                    <div className="col-lg-7 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="banner-wrapper-image">
                                <img src={bannerImg2} alt="banner" />
                            </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MainBanner;