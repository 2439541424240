import React from 'react'
import {Link} from 'gatsby'


/* Colocar imagenes nuevas */

import icon6 from '../../assets/images/icons/B_Web/6.png'
import icon5 from '../../assets/images/icons/B_Web/5.png'
import icon4 from '../../assets/images/icons/B_Web/4.png'
import icon3 from '../../assets/images/icons/B_Web/3.png'
import icon2 from '../../assets/images/icons/B_Web/2.png'
import icon1 from '../../assets/images/icons/B_Web/1.png'

import StarIcon from '../../assets/images/star-icon.png'

const Solutions = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                      {/*   <img src={StarIcon} alt="StarIcon" />  */}
                        Puntos claves
                    </span> 
                    <h2>Puntos clave del desarrollo web en empresas</h2>
                    <p>Si no sabes como iniciar en el proceso de identificación de marca de tu sitio web, aquí te presentamos unos puntos que tienes que tomar en cuenta.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <img src={icon6} alt="banner" />
                            </div>

                            <h3>
                                <Link to="/contact">
                                Aumenta tu visión de negocio
                                </Link>
                            </h3>
                            <p>Una de las ventajas indiscutibles es que aumenta el alcance de tu negocio, ya que no solo te verán los que vayan de manera directa a tus sucursales u oficinas, sino que cualquier persona con acceso a internet podrán ver lo que ofreces.</p>

                          {/*   <Link to="/contact" className="view-details-btn">
                            Ver detalles
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <img src={icon5} alt="banner" />
                            </div>
                            <h3>
                                <Link to="/contact">
                                Mejora la imagen de tu marca
                                </Link>
                            </h3>
                            <p>Algo que tiene que ver con el punto anterior, es que si tienes un buen diseño web vas a mejorar la imagen de tu marca.Hoy en día quienes no están en internet no existen, y el tener tu propio sitio web es una declaración de que tu negocio es profesional. </p>
                        
                            
                          {/*   <Link to="/contact" className="view-details-btn">
                            Ver detalles
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <img src={icon4} alt="banner" />
                            </div>
                            <h3>
                                <Link to="/contact">
                                Aumenta tus ventas
                                </Link>
                            </h3>
                            <p>El tener un sitio web es como tener tu negocio abierto 24/7, lo que hace que tus clientes puedan revisar tu catálogo de productos o servicios en cualquier momento y de esa forma te ahorrarías tiempo en publicar en otros medios digitales.</p>
                            
                          {/*   <Link to="/contact" className="view-details-btn">
                            Ver detalles
                            </Link> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <img src={icon3} alt="banner" />
                            </div>
                            <h3>
                                <Link to="/contact">
                                Te permite vender en línea
                                </Link>
                            </h3>
                            <p>Debido al punto anterior es que los sitios de e-commerce están en apogeo, puesto que ofrecen la posibilidad de realizar todo el proceso de venta desde el sitio web.</p>

                          {/*   <Link to="/contact" className="view-details-btn">
                            Ver detalles
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">

                            <img src={icon2} alt="banner" />

                            </div>
                            <h3>
                                <Link to="/contact">
                                Mejora el servicio al cliente
                                </Link>
                            </h3>
                         
                            <p>Repetimos lo de “tener un sitio web es como tener tu negocio abierto 24/7”, porque esto también mejora en gran medida el servicio que le puedas dar a tu cliente.</p>
                            
                          {/*   <Link to="/contact" className="view-details-btn">
                            Ver detalles
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <img src={icon1} alt="banner" />
                            </div>
                            <h3>
                                <Link to="/contact">
                                S.E.O
                                </Link>
                            </h3>
                            <p>Si ya has leído nuestros artículos sobre qué es SEO y cuál es su importancia y cómo realizar una auditoria SEO a tu sitio web, seguro ya sabes lo importante que es S.E.O</p>
                            
                          {/*   <Link to="/contact" className="view-details-btn">
                            Ver detalles
                            </Link> */}
                        </div>
                    </div>
                </div>

                
            </div>
        </section>
    )
}

export default Solutions;