import React from 'react';


import icon1 from '../../assets/images/icons/icon1.png'
import icon2 from '../../assets/images/icons/icon2.png'
import icon8 from '../../assets/images/icons/icon8.png'
import icon9 from '../../assets/images/icons/icon9.png'
import icon10 from '../../assets/images/icons/icon10.png'
import icon11 from '../../assets/images/icons/icon11.png'


const Services = () => {
    return (

        
        <div className="boxes-area pb-70">
            <div className="container">
            <div className="section-title">

            <span className="sub-title">
                      {/*   <img src={StarIcon} alt="StarIcon" />  */}
                     SERVICIOS
                    </span> 

                    <h2>Nuestro servicio en desarrollo web abarca</h2>
{/*                     <p>Las siguientes ramas de la industria de desarrollo web</p> */}
                </div>

                <div className="row">
            



                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">

                                <img src={icon1} alt="banner" />

                            </div>
                            <h3>
                                Páginas web informativas
                            </h3>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                            
                        </div>
                    </div>


                    
                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="banner" />
                            </div>
                            <h3>

                                Landing Page
 
                            </h3><br></br>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>



                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon11} alt="banner" />
                            </div>
                            <h3>

                                Sitios Web

                            </h3><br></br>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>


                    
                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon9} alt="banner" />
                            </div>
                            <h3>
              
                                E-commerce
                    
                            </h3><br></br>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon10} alt="banner" />
                            </div>
                            <h3>
                     
                                Desarrollo de sistemas web
                 
                            </h3>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>


                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon8} alt="banner" />
                            </div>
                            <h3>
                            
                                SEO
                          
                            </h3><br></br>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>


                </div>




            </div>
        </div>
    )
}
export default Services;