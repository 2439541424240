import React from 'react';
import {Link} from 'gatsby'

import AboutImg from '../../assets/images/about/about-img9.jpg'
import StarIcon from '../../assets/images/star-icon.png'
import Icon4 from '../../assets/images/icons/icon4.png'
import Icon5 from '../../assets/images/icons/icon5.png'
import Icon6 from '../../assets/images/icons/icon6.png'
import Icon7 from '../../assets/images/icons/icon12.png'
import Icon8 from '../../assets/images/icons/icon13.png'
import Icon9 from '../../assets/images/icons/icon14.png'

import CircleShape1 from '../../assets/images/shape/circle-shape1.png'

import Loadable from '@loadable/component'
const ModalVideo = Loadable(() => import('react-modal-video'))

const AboutUs = () => {
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }

    return (
        <React.Fragment>
            {/* If you want to change the video need to update videoID */}
            <ModalVideo 
                channel='youtube' 
                isOpen={!isOpen} 
                videoId='bk7McNUjWgw' 
                onClose={() => setIsOpen(!isOpen)} 
            />

            <div className="about-area pb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-image-box">
                                <img src={AboutImg} alt="AboutImg" />
                                
                                {/* <div className="box">
                                    <Link 
                                        to="#play-video" 
                                        onClick={e => {e.preventDefault(); openModal()}} 
                                        className="video-btn popup-youtube"
                                    >
                                        <i className="flaticon-google-play"></i>Ver video
                                    </Link>
                                </div> */}


                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <span className="sub-title">
                                    {/*     <img src={StarIcon} alt="StarIcon" />  */}
                                        Tecnologías
                                    </span>
                                    <h2>La aplicación para la mentalidad con visión de futuro</h2>
                                    <p>Nuestra experiencia nos identifica</p>
                                    
                                    <ul className="features-list">
                                        <li>
                                            <img src={Icon4} alt="Icon4" />
                                            <h3>HTML</h3>
                                          
                                        </li>
                                        <li>
                                            <img src={Icon5} alt="Icon5" />
                                            <h3>JAVASCRIPT</h3>
                                          
                                        </li>
                                        <li>
                                            <img src={Icon6} alt="Icon4" />
                                            <h3>CSS</h3>
                                          
                                        </li>

                                        <li>
                                            <img src={Icon7} alt="Icon5" />
                                            <h3>PHP</h3>
                                          
                                        </li>
                                        <li>
                                            <img src={Icon8} alt="Icon5" />
                                            <h3>REACT</h3>
                                          
                                        </li>
                                        <li>
                                            <img src={Icon9} alt="Icon5" />
                                            <h3>YII 2</h3>
                                          
                                        </li>
                                    </ul>
                                    <p>Si quieres saber sobre nuestro inicio en la industria tecnología te invitamos acceder en el siguiente botón</p>

                                    <Link to="/nosotros" className="default-btn">
                                        <i className="flaticon-right"></i> 
                                        Más acerca de nosotros <span></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className="circle-shape1">
                    <img src={CircleShape1} alt="CircleShape" />
                </div>
            </div>
        </React.Fragment>
    )
}

export default AboutUs;